<template>
  <div
    v-if="!hideSmsBanner"
    class="sms-banner"
  >
    <div class="container q-py-md q-px-lg-xl q-px-md-lg row justify-between items-center relative-position">
      <div class="text-22-700 col-3 gt-md">{{ $t('text.boostConversion') }}</div>
      <q-img
        src="~/assets/rocket.svg"
        class="rocket-img gt-md"
        width="87px"
      />
      <div class="col-12 col-lg-9 row justify-between items-center">
        <div class="text-16-400 desc col">{{ $t('text.boostConversionText') }}</div>
        <q-icon
          name="fa fa-xmark"
          class="cursor-pointer"
          size="17px"
          @click="hideBanner"
        />
      </div>
      <q-img
        src="~/assets/arrow_conversion.svg"
        class="arrow-img"
        width="30px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmsHeaderBanner',
  data() {
    return {
      hideSmsBanner: true,
    };
  },
  created() {
    this.hideSmsBanner = this.$q.cookies.has('hideSmsBanner');
  },
  methods: {
    hideBanner() {
      this.hideSmsBanner = true;
      this.$q.cookies.set('hideSmsBanner', this.hideSmsBanner, { expires: '7d' });
    },
  },
};
</script>

<style scoped lang="scss">
.sms-banner {
  background: $primary;

  .rocket-img {
    position: absolute;
    top: -2px;
    left: 310px;
  }

  .arrow-img {
    position: absolute;
    top: 34px;
    right: 342px;
  }
}
</style>
