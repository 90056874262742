<template>
  <div class="flex">
    <ActionsDropdown
      :menu="actions"
      class="no-hover text-13-400"
      color="primary"
      dropdown-icon="expand_more"
    >
      <template
        v-if="isAuth"
        #label
      >
        <div class="flex items-center">
          <q-img
            class="profile q-mr-sm"
            src="~/assets/profile_circle.svg"
          />
          <div class="text-dark text-16-700">
            {{ account.firstName }} {{ account.lastName }}
          </div>
        </div>
      </template>
    </ActionsDropdown>

    <LogoutDialog
      v-if="isShowLogoutDialog"
      @close="isShowLogoutDialog = false"
      @sign-out="signOut"
    />
  </div>
</template>

<script>
import ActionsDropdown from 'common/components/ActionsDropdown';
import LogoutDialog from 'common/components/dialogs/LogoutDialog';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ProfileMenu',
  components: {
    LogoutDialog,
    ActionsDropdown,
  },
  data() {
    return {
      isShowLogoutDialog: false,
    };
  },
  computed: {
    ...mapState('app', ['account']),
    ...mapGetters('app', ['isAuth']),
    actions() {
      return [
        {
          title: this.$t('title.profile'),
          icon: 'fl:person_fill',
          route: { name: ENUMS.ROUTES.PROFILE.INDEX },
        },
        {
          title: this.$t('title.logout'),
          icon: 'fl:sign_in_squre',
          handler: this.showLogoutDialog,
        },
      ];
    },
  },
  methods: {
    ...mapActions('app', ['logout']),
    signOut() {
      this.logout();
      this.$router.push({ name: ENUMS.ROUTES.AUTH.SIGNIN });
    },
    showLogoutDialog() {
      this.isShowLogoutDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.profile {
  width: 28px;
}
</style>
