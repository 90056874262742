<template>
  <div class="flex">
    <div
      v-if="hasReminders"
      class="row flex items-center q-gutter-x-md cursor-pointer"
    >
      <div class="flex q-gutter-x-xs items-center">
        <q-icon
          name="fl:chat_fill"
          color="primary"
          size="22px"
        />
        <div class="text-16-700">{{ $t('label.reminders') }}</div>
      </div>
      <q-slider
        v-if="isOneReminder"
        dense
        track-size="1px"
        thumb-path=""
        readonly
        class="header-slider gt-md"
        :value="reminders[0].available"
        :min="0"
        :max="reminders[0].limit"
      />
      <AppButtonLink v-bind="counterButtonAttributes"/>
      <q-menu
        content-class="boost-conversion-menu"
        content-style="min-height: 278px"
        :offset="[-30, 25]"
      >
        <div class="q-pa-md column q-col-gutter-y-md text-center relative-position body">
          <div>
            <q-icon
              name="fl:chat_fill"
              color="primary"
              size="72px"
            />
          </div>
          <div class="text-14-700 text-primary q-px-md">{{ $t('text.sendingWorkingSuccessfully') }}</div>
        </div>

        <div class="footer q-pa-md">
          <div
            v-for="reminder in reminders"
            :key="reminder.label"
          >
            <div class="flex justify-between">
              <div class="text-grey">{{ reminder.label }}</div>
              <div>{{ reminder.available }} / {{ reminder.limit }}</div>
            </div>

            <q-slider
              dense
              track-size="1px"
              thumb-path=""
              readonly
              :value="reminder.available"
              :min="0"
              :max="reminder.limit"
            />
          </div>
          <AppButton
            v-close-popup
            :label="$t('button.increaseSms')"
            icon-left="fa-regular fa-comment-dots"
            icon-left-size="14px"
            icon-right="fa fa-plus"
            icon-right-size="14px"
            class="text-16-400"
            color="grey-2"
            text-color="black"
            padding="7px 32px"
            @click="showTariffDialog = true"
          />
        </div>
      </q-menu>
    </div>
    <AppButtonLink
      v-else
      :label="$t('button.increaseConversion')"
      color="dark text-16-700"
      class="btn-conv"
      icon-left="fl:chat_fill"
      icon-left-size="20px"
      icon-left-color="primary"
      icon-right="fl:plus"
      icon-right-size="20px"
      icon-right-color="primary"
    >
      <q-menu
        content-class="boost-conversion-menu"
        content-style="min-height: 292px"
        :offset="[18, 25]"
      >
        <div class="q-pa-md column q-col-gutter-y-md text-center relative-position body">
          <div class="rocket-img">
            <q-img
              src="~/assets/rocket.svg"
              width="78px"
            />
          </div>
          <div class="text-18-700 q-mt-xl q-px-md">
            {{ $t('text.boostConversion') }}
          </div>
          <div class="text-grey q-px-md">
            {{ $t('text.boostConversionText') }}
          </div>
        </div>

        <div class="footer q-pa-sm flex justify-center">
          <AppButton
            v-close-popup
            :label="$t('button.choosePlan')"
            icon-left="fa-regular fa-comment-dots"
            icon-left-size="14px"
            class="text-16-400"
            color="grey-2"
            text-color="black"
            padding="7px 38px"
            @click="showTariffDialog = true"
          />
        </div>
      </q-menu>
    </AppButtonLink>

    <TariffDialog
      v-if="showTariffDialog"
      is-open
      @close="showTariffDialog = false"
    />
  </div>
</template>

<script>
import AppButton from 'common/components/buttons/AppButton';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import TariffDialog from 'pages/dashboard/form/components/form-fields/TariffDialog';
import { mapState } from 'vuex';

export default {
  name: 'IncreaseConversionButton',
  components: {
    TariffDialog,
    AppButton,
    AppButtonLink,
  },
  data() {
    return {
      showTariffDialog: false,
    };
  },
  computed: {
    ...mapState('app', ['account']),
    counterButtonAttributes() {
      const attributes = {
        class: 'text-16-700 gt-md',
        textColor: 'black',
      };
      let iconKey;

      if (this.isOneReminder) {
        iconKey = 'iconRight';
        attributes.label = `${this.reminders[0].available} / ${this.reminders[0].limit}`;
      } else {
        iconKey = 'iconLeft';
      }

      attributes[iconKey] = 'expand_more';
      attributes[`${iconKey}Color`] = 'primary';
      attributes[`${iconKey}Size`] = '22px';

      return attributes;
    },
    isOneReminder() {
      return this.reminders.length === 1;
    },
    hasReminders() {
      return this.reminders.length > 0;
    },
    reminders() {
      const labels = {
        [ENUMS.NOTIFICATION_TYPES.SMS]: this.$t('title.sms'),
        [ENUMS.NOTIFICATION_TYPES.WHATS_APP]: this.$t('title.whatsApp'),
        [ENUMS.NOTIFICATION_TYPES.TELEGRAM]: this.$t('title.telegram'),
      };
      return Object.keys(this.account.reminders)
        .filter((key) => this.account.reminders[key])
        .flatMap((key) => {
          const reminder = this.account.reminders[key];
          return {
            ...reminder,
            available: reminder.limit - reminder.sent,
            label: labels[key],
          };
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header-slider {
  width: 50px;
}
</style>

<style lang="scss">
.boost-conversion-menu {
  border-radius: $radius-20;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.05);
  width: 226px;
  overflow: visible;

  .body {
    border-bottom: 1px solid $grey-2;

    .rocket-img {
      position: absolute;
      width: 100%;
      left: 0;
      top: -20px;
    }
  }

  .footer {
    button {
      border-radius: $radius-10;
    }
  }
}
</style>
