<template>
  <div class="header items-center row">
    <div
      v-if="account"
      class="flex col"
    >
      <AppButtonLink
        v-if="showDashboardLink"
        :label="$t('button.backDash')"
        :to="{ name: ENUMS.ROUTES.DASHBOARD.INDEX }"
        :flat="false"
        class="text-16-400"
        icon-left="fa fa-angle-left"
        icon-left-size="19px"
        color="primary"
        text-color="white"
        padding="11px 22px"
      />
      <AppButtonLink
        v-else
        :label="$t('button.createNewForm')"
        :disabled="!account.capabilities.canManageForm"
        :flat="false"
        class="text-16-400"
        icon-left="fl:chat_plus"
        icon-left-size="19px"
        color="primary"
        text-color="white"
        padding="11px 22px"
        @click="openNewForm"
      />
      <AppButtonLink
        href="https://support.webbymize.com/hc/de/sections/7781200734109-Tutorials"
        target="_blank"
        class="text-12-400 q-ml-md"
        color="dark-1"
        icon-left="fl:videocam"
        icon-left-size="14px"
        :label="$t('button.tutorials')"
      />
    </div>
    <div :class="[account ? 'col-shrink' : 'col', 'text-center flex content-center']">
      <WebbymizeLogo show-switcher/>
    </div>
    <div
      v-if="account"
      class="row col justify-end text-right q-gutter-x-lg"
    >
      <IncreaseConversionButton/>
      <LanguageSelector/>
      <ProfileMenu/>
    </div>
  </div>
</template>

<script>
import LanguageSelector from 'common/components/LanguageSelector';
import WebbymizeLogo from 'common/components/WebbymizeLogo';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import ProfileMenu from 'src/components/ProfileMenu';
import IncreaseConversionButton from 'src/components/buttons/IncreaseConversionButton';
import { createFormMixin } from 'src/mixins';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'DashboardHeader',
  components: {
    IncreaseConversionButton,
    ProfileMenu,
    LanguageSelector,
    WebbymizeLogo,
    AppButtonLink,
  },
  mixins: [createFormMixin],
  computed: {
    ...mapState('app', ['account']),
    ...mapGetters('app', ['isActiveAccount']),
    showDashboardLink() {
      return [
        ...Object.values(ENUMS.ROUTES.FORM),
        ...Object.values(ENUMS.ROUTES.PROFILE),
      ].includes(this.$route.name);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 90px;
}
</style>
