<template>
  <MainLayout>
    <MasqueradeMenu v-if="isMasquerade"/>
    <SmsHeaderBanner v-if="account && !account.sms"/>
    <q-layout
      class="container q-px-xl column"
      view="hHh lpR fFf"
    >
      <DashboardHeader/>
      <q-page-container class="col column">
        <q-page
          class="col column"
          :style-fn="styleFn"
        >
          <router-view class="col"/>
          <MainFooter class="q-py-xl"/>
        </q-page>
      </q-page-container>

      <FlashDialog
        :is-open="isCopied"
        @close="setCopied(false)"
      >
        <q-icon name="fl:ring_bold"/>
        <span>{{ $t('text.codeCopiedSuccessfully') }}</span>
      </FlashDialog>
    </q-layout>
  </MainLayout>
</template>

<script>
import MainFooter from 'common/components/MainFooter';
import FlashDialog from 'common/components/dialogs/FlashDialog';
import MainLayout from 'common/layouts/MainLayout';
import { masqueradeMixin } from 'common/mixins';
import DashboardHeader from 'src/components/DashboardHeader';
import MasqueradeMenu from 'src/components/MasqueradeMenu';
import SmsHeaderBanner from 'src/components/SmsHeaderBanner';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'DashboardLayout',
  components: {
    MasqueradeMenu,
    SmsHeaderBanner,
    FlashDialog,
    DashboardHeader,
    MainFooter,
    MainLayout,
  },
  mixins: [masqueradeMixin],
  computed: {
    ...mapState('app', ['layout', 'account']),
    ...mapState('dashboard', ['isCopied']),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('app', ['getLayout']),
    ...mapMutations('dashboard', ['setCopied']),
    init() {
      if (this.layout) {
        return;
      }
      this.getLayout();
    },
    styleFn() {
      return {};
    },
  },
};
</script>
