<template>
  <div class="masquerade-menu flex justify-between items-center">
    <div
      class="flex q-px-lg back-block full-height cursor-pointer items-center"
      @click="onBackToAffiliate"
    >
      <q-icon
        name="fl:expand_left"
        size="18px"
        color="white"
      />
      <q-avatar
        v-if="avatar"
        size="30px"
        class="q-ml-sm q-mr-md"
      >
        <q-img :src="avatar"/>
      </q-avatar>
      <div
        v-t="'text.backToMyProfile'"
        class="text-white"
      />
    </div>

    <ActionsDropdown
      :menu="actions"
      :menu-offset="[-10, 10]"
      class="text-white text-14-400 q-px-lg"
      content-class="child-menu border-radius-10"
      dropdown-icon="expand_more"
    >
      <template #label>
        <div class="q-mr-sm">
          {{ account.displayName }}
        </div>
      </template>
    </ActionsDropdown>
  </div>
</template>

<script>
import ActionsDropdown from 'common/components/ActionsDropdown';
import { setCookie } from 'common/helpers/cookies';
import { copyCodeMixin, masqueradeMixin } from 'common/mixins';
import { mapState } from 'vuex';

export default {
  name: 'MasqueradeMenu',
  components: { ActionsDropdown },
  mixins: [copyCodeMixin, masqueradeMixin],
  computed: {
    ...mapState('app', ['account']),
    avatar() {
      return this.account.parent.certifiedAccount?.photo;
    },
    actions() {
      return [
        ...this.account.parent.childUsers
          .filter(({ id }) => id !== this.account.id)
          .map((user) => ({
            title: user.displayName,
            handler: () => {
              this.onLoginAsClient(user.id);
            },
          })),
        {
          title: this.$t('button.addNewClient'),
          icon: 'fl:add_round',
          handler: () => {
            this.copyCode(this.account.parent.certifiedAccount.purchaseURL);
          },
        },
      ];
    },
  },
  created() {
    if (this.$route.query.clientToken) {
      this.$router.replace({
        path: this.$route.path,
        query: {},
      });
    }
  },
  methods: {
    onBackToAffiliate() {
      const options = {
        path: '/',
        domain: process.env.COOKIE_HOST,
      };
      if (this.$q.cookies.has('sourceAccessToken')) {
        setCookie('accessToken', this.$q.cookies.get('sourceAccessToken'), options);
        this.$q.cookies.remove('sourceAccessToken', options);
      }
      this.$q.cookies.remove('isMasquerade', options);
      window.location.href = process.env.AFFILIATE_DOMAIN;
    },
  },
};
</script>

<style scoped lang="scss">
.masquerade-menu {
  background: #3A4542;
  height: 60px;

  .back-block {
    text-decoration: none;
    transition: all 0.25s ease;

    &:hover {
      background: #4F5856;
    }
  }

  .q-btn--actionable {
    border-radius: 0;

    ::v-deep .q-btn__wrapper {
      padding: 18px 2px !important;
    }
  }
}
</style>

<style lang="scss">
.child-menu {
  background: #3A4542;

  hr {
    margin: 0;
    background: #4F5856 !important;
  }

  .list-link {
    background: unset;
    border-radius: 0 !important;
    padding: 9px 20px !important;
    margin: 0;
    transition: all 0.25s ease;

    &:last-of-type {
      .q-item__label {
        color: $primary !important;
        font-size: 14px !important;
      }
      .q-icon {
        color: $primary !important;
      }
    }

    &:hover {
      background: #4F5856;
    }

    .q-item__label {
      color: white !important;
      font-size: 14px !important;
    }
  }
}
</style>
