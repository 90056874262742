import { render, staticRenderFns } from "./IncreaseConversionButton.vue?vue&type=template&id=25964e76&scoped=true&"
import script from "./IncreaseConversionButton.vue?vue&type=script&lang=js&"
export * from "./IncreaseConversionButton.vue?vue&type=script&lang=js&"
import style0 from "./IncreaseConversionButton.vue?vue&type=style&index=0&id=25964e76&prod&scoped=true&lang=scss&"
import style1 from "./IncreaseConversionButton.vue?vue&type=style&index=1&id=25964e76&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25964e76",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QImg from 'quasar/src/components/img/QImg.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QSlider,QMenu,QImg});qInstall(component, 'directives', {ClosePopup});
